import React, { useEffect } from "react";
import { Link } from "gatsby";

import PageLayout from "../../components/page-layout";
import CallCount from "../../components/call-count";
import TeamSection from "../../components/sections/team";
import HowItWorksSection from "../../components/sections/how-it-works";
import Testimonials from "../../components/testimonials";

import ukraineImage from "../../images/ukraine.png";

import * as style from "../index.module.css";

const start = () =>
  (function (d, s, id) {
    var js;
    if (d.getElementById(id)) {
      return;
    }
    js = d.createElement(s);
    js.id = id;
    js.src = "https://embedsocial.com/cdn/ht.js";
    d.getElementsByTagName("head")[0].appendChild(js);
  })(document, "script", "EmbedSocialHashtagScript");

const IndexPage = () => {
  useEffect(() => {
    start();
  }, []);

  return (
    <PageLayout
      title="Запитай у колеги"
      description="Peer to Peer Career Mentoring for professionals forced to flee"
      image={ukraineImage}
      language="ua"
    >
      <section id="above-fold" className={style.aboveFold}>
        <img src={ukraineImage} width="64" height="42" />
        <h1>Запитай у колеги</h1>

        <div className={style.callCountDisplay}>
          <div>
            <CallCount />
          </div>
          <div>дзвінків здійснено</div>
        </div>

        <ul>
          <li>
            Ти спеціаліст, який постраждав від російської агресії в Україні?
          </li>
          <li>У тебе виникають питання про особливості роботи в Європі?</li>
          <li>
            Запитай у колеги, який вже працює на схожій посаді за кордоном!
          </li>
        </ul>

        <br />
        <p>
          <Link to="./step1" className="cac-button">
            Забронювати дзвінок
          </Link>
        </p>
      </section>

      <HowItWorksSection
        title="Як це працює?"
        items={[
          {
            title: "Крок 1",
            text: "Обери свою професійну сферу.",
          },
          {
            title: "Крок 2",
            text: "Обери свого ментора та зручний час.",
          },
          {
            title: "Крок 3",
            text: "Поділись своїми контактами, щоб ментор зміг з тобою зв’язатись та підтвердити час.",
          },
          {
            title: "Крок 4",
            text: "Знайди на своїй електронній пошті лист про підтвердження дзвінка. Якщо тобі необхідно відхилити запланований дзвінок - скористайся посиланням всередині нього.",
          },
          {
            title: "Пам’ятай",
            text: "Ментори - це волонтери, які знаходять час, щоб приділити його тобі (деякі навіть домовляються з нянею). Будь ласка, будь готовий до дзвінка або скасуй його завчасно.",
          },
        ]}
      />

      <section id="testimonials" className={style.testimonialSection}>
        <h2>Ми вже допомогли:</h2>
        <Testimonials />
      </section>

      <TeamSection language="ua" />

      <section className={style.embedSection}>
        <h2>Більше кар’єрних порад</h2>

        <div
          dangerouslySetInnerHTML={{
            __html:
              '<!-- LightWidget WIDGET --><script src="https://cdn.lightwidget.com/widgets/lightwidget.js"></script><iframe src="//lightwidget.com/widgets/0ad6ba9088a25f31868d3132c551fa7a.html" scrolling="no" allowtransparency="true" class="lightwidget-widget" style="width:100%;border:0;overflow:hidden;"></iframe>',
          }}
        />
      </section>
    </PageLayout>
  );
};

export default IndexPage;
